define("discourse/plugins/discourse-user-post-count/discourse/components/user-post-count", ["exports", "@ember/component", "@ember/service", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _service, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UserPostCount extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.shouldRender}}
          <div class="user-post-count" data-count={{this.postCount}}>
            {{this.content}}
          </div>
        {{/if}}
      
    */
    {
      "id": "7GC2q/ti",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"      \"],[10,0],[14,0,\"user-post-count\"],[15,\"data-count\",[30,0,[\"postCount\"]]],[12],[1,\"\\n        \"],[1,[30,0,[\"content\"]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-user-post-count/discourse/components/user-post-count.js",
      "isStrictMode": true
    }), this))();
    get shouldRender() {
      return this.postCount > 0;
    }
    get content() {
      return (0, _discourseI18n.i18n)("user_post_count.content", {
        count: this.postCount
      });
    }
    get postCount() {
      return (this.siteSettings.user_post_count_include_topic ? this.outletArgs?.user.post_count + this.outletArgs?.user.topic_count : this.outletArgs?.user.post_count) || this.count || 0;
    }
  }
  _exports.default = UserPostCount;
});