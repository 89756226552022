define("discourse/plugins/discourse-user-post-count/discourse/initializers/user-post-count", ["exports", "discourse/lib/api", "discourse/widgets/render-glimmer", "discourse/plugins/discourse-user-post-count/discourse/components/user-post-count", "@ember/template-factory"], function (_exports, _api, _renderGlimmer, _userPostCount, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    if (siteSettings.user_post_count_in_post) {
      api.includePostAttributes("user_post_count");
      api.includePostAttributes("user_topic_count");
      (0, _renderGlimmer.registerWidgetShim)("user-post-count", "span.user-post-user__container", (0, _templateFactory.createTemplateFactory)(
      /*
        <UserPostCount @count={{ @data.count }} />
      */
      {
        "id": "TdlhLUVx",
        "block": "[[[8,[39,0],null,[[\"@count\"],[[30,1,[\"count\"]]]],null]],[\"@data\"],false,[\"user-post-count\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-user-post-count/discourse/initializers/user-post-count.js",
        "isStrictMode": false
      }));
      api.decorateWidget("poster-name:after", helper => {
        return helper.attach("user-post-count", {
          count: siteSettings.user_post_count_include_topic ? helper.attrs.user_post_count + helper.attrs.user_topic_count : helper.attrs.user_post_count
        });
      });
    }
    if (siteSettings.user_post_count_in_usercard) {
      api.renderInOutlet("user-card-post-names", _userPostCount.default);
    }
  });
});